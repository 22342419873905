import {
  FieldMetadata,
  getFormProps,
  getInputProps,
  useForm,
} from "@conform-to/react";
import { getZodConstraint, parseWithZod } from "@conform-to/zod";
import { Form, Link } from "@remix-run/react";
import { $path } from "remix-routes";
import { z } from "zod";
import { SocialLogin } from "~/components/auth/SocialLogin";
import { Button } from "~/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/components/ui/card";
import { Input } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { cn } from "~/lib/utils/tailwind.util";

export const loginSchema = z.object({
  email: z
    .string({ required_error: "Email is required" })
    .email("Email is invalid"),
  password: z.string({ required_error: "Password is required" }),
});

export type LoginFormProps = {
  className?: string;
  enableGithub?: boolean;
  hasError?: boolean;
};

export function LoginForm({
  className,
  enableGithub,
  hasError,
}: LoginFormProps) {
  const [form, fields] = useForm<z.infer<typeof loginSchema>>({
    constraint: getZodConstraint(loginSchema),
    onValidate({ formData }) {
      return parseWithZod(formData, { schema: loginSchema });
    },
    shouldRevalidate: "onInput",
    shouldValidate: "onBlur",
  });

  type FormFieldErrorProps = {
    field: FieldMetadata;
  };
  function FormFieldError({ field }: FormFieldErrorProps) {
    return (
      <div id={field.errorId} className="text-sm text-red-400">
        {field.errors}
      </div>
    );
  }

  return (
    <Card className={cn("w-full max-w-sm", className)}>
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <CardDescription>
          Welcome back! Please log in to continue.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4">
        <SocialLogin enableGithub={enableGithub} />

        <div className="flex items-center">
          <span className="flex-1 border-t"></span>
          <span className="m-4 text-sm text-muted-foreground">or</span>
          <span className="flex-1 border-t"></span>
        </div>

        {hasError && (
          <div className="rounded-md border border-red-500 bg-red-100 p-2 text-sm text-red-700">
            Login failed, please try again.
          </div>
        )}

        <Form method="POST" className="grid gap-4" {...getFormProps(form)}>
          <div id={form.errorId}>{form.errors}</div>
          <div className="grid gap-2">
            <Label htmlFor={fields.email.id}>Email</Label>
            <Input {...getInputProps(fields.email, { type: "email" })} />
            <FormFieldError field={fields.email} />
          </div>
          <div className="grid gap-2">
            <Label htmlFor={fields.password.id}>Password</Label>
            <Input
              {...getInputProps(fields.password, { type: "password" })}
              autoComplete="current-password"
            />
            <FormFieldError field={fields.password} />
          </div>
          <Button type="submit" className="w-full">
            Login
          </Button>
        </Form>
        <div className="text-center text-sm text-muted-foreground">
          <p>
            Or register here:
            <Link to={$path("/register")}>
              <Button variant="link">Register</Button>
            </Link>
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
