import { parseWithZod } from "@conform-to/zod";
import { ActionFunctionArgs, data, LoaderFunctionArgs } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { $path } from "remix-routes";
import { LoginForm, loginSchema } from "~/components/auth/LogInForm";
import { authenticator } from "~/lib/services/auth.server";
import { commitSession, getSession } from "~/lib/services/session.server";
import { config } from "~/lib/utils/config.server";
import { createLogger } from "~/lib/utils/logger.server";

const logger = createLogger(import.meta.filename);

export const loader = async ({ request }: LoaderFunctionArgs) => {
  await authenticator.isAuthenticated(request, {
    successRedirect: $path("/app"),
  });

  const session = await getSession(request.headers.get("cookie"));
  const error = session.get(authenticator.sessionErrorKey);

  if (error) {
    logger.debug(error, "Failed to log in");
  }
  // TODO check and remove

  return data(
    {
      hasError: !!error,
      enableGithub: config.authentication.github.enabled,
    },
    { headers: { "Set-Cookie": await commitSession(session) } },
  );
};

export async function action({ request }: ActionFunctionArgs) {
  await authenticator.authenticate("user-pass", request, {
    successRedirect: $path("/app"),
    failureRedirect: $path("/login"), // TODO check and remove
  });

  // TODo remove
  logger.debug(`Should never get here: Failed to login`);

  const formData = await request.formData();
  const submission = parseWithZod(formData, { schema: loginSchema });

  if (submission.status !== "success") {
    return submission.reply();
  }

  // no redirect on success -> failed
  return submission.reply({ formErrors: ["Incorrect email or password"] });
}

export default function Page() {
  // TODO maybe use error from useActionData()
  const { hasError, enableGithub } = useLoaderData<typeof loader>();

  return (
    <div className="flex h-full items-center justify-center">
      <LoginForm
        className="m-2"
        enableGithub={enableGithub}
        hasError={hasError}
      />
    </div>
  );
}
